// eslint-disable-next-line import/named
import { ReturnValue } from '@enzoferey/ethers-error-parser'
import { ToastMessageType } from './types'

const useToast = () => {
  interface ToastState {
    currentToasts: ToastMessageType[]
    runningNumber: number
  }

  const toastState = useState<ToastState>('toast', () => {
    return {
      currentToasts: [],
      runningNumber: 0
    }
  })

  const removeToast = (selectedToastId: number) => {
    const filteredToast = toastState.value.currentToasts.filter(
      toast => toast.toastId !== selectedToastId
    )
    toastState.value.currentToasts = filteredToast
  }

  const addNewToast = (
    title: string,
    message: string | ReturnValue,
    type: 'success' | 'info' | 'warning' | 'error',
    link?: string
  ) => {
    if (toastState.value.currentToasts.length > 4) {
      toastState.value.currentToasts.splice(0, 1)
    }
    toastState.value.currentToasts.push({
      toastId: toastState.value.runningNumber,
      toastTitle: title,
      toastMessage: message,
      toastType: type,
      toastLink: link
    })
    toastState.value.runningNumber = toastState.value.runningNumber + 1
  }

  const addNewErrorToast = (
    message: string | ReturnValue,
    title?: string,
    link?: string
  ) => {
    addNewToast(title || 'Error', message, 'error', link)
  }

  const addNewSuccessToast = (
    message: string,
    title?: string,
    link?: string
  ) => {
    addNewToast(title || 'Success', message, 'success', link)
  }

  const addNewInfoToast = (message: string, title?: string, link?: string) => {
    addNewToast(title || 'Info', message, 'info', link)
  }

  const addNewWarningToast = (
    message: string | ReturnValue,
    title?: string,
    link?: string
  ) => {
    addNewToast(title || 'Warning', message, 'warning', link)
  }
  return {
    toastState,
    removeToast,
    addNewErrorToast,
    addNewSuccessToast,
    addNewInfoToast,
    addNewWarningToast
  }
}

export default useToast
