// eslint-disable-next-line import/named
import { ReturnValue } from '@enzoferey/ethers-error-parser'

export interface LoginResponseType {
  status: number
  data: {
    refresh_token: string
    session_token: string
    username: string
  }
}

export interface GenerateQrResponseType {
  status: number
  data?: {
    qr_token: string
  }
  message?: string
}

export interface UpdateUsernameResponseType {
  status: number
  data: {
    username: string
  }
}

export type ToastMessageType = {
  toastId: number
  toastTitle: string
  toastMessage: string | ReturnValue
  toastType: 'success' | 'info' | 'warning' | 'error'
  toastLink?: string
}

export interface StatsTokenomics {
  name: string
  address: string
  pymAmount: string
}

export interface CoinGeckoApiResponse {
  [key: string]: {
    usd: number
  }
}

export interface ConnectWalletTypes {
  isConnected: boolean
  userWallet?: string
  chainId?: number
}

interface MetamaskError {
  message: string
  reason: string
}

// Type Guard
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isMyError (error: any): error is MetamaskError {
  return typeof error.code === 'string'
}

declare global {
  interface Window {
    ethereum: import('ethers').providers.ExternalProvider
  }
}
