const useWaitingModal = () => {
  const isWaiting = useState('waiting', () => false)

  const closeWaiting = () => {
    isWaiting.value = false
  }

  const openWaiting = () => {
    isWaiting.value = true
  }

  return {
    isWaiting,
    closeWaiting,
    openWaiting
  }
}

export default useWaitingModal
