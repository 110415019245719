import { defineStore, skipHydrate } from 'pinia'
import { parseFixed, BigNumber } from '@ethersproject/bignumber'
import { parseEther, parseUnits } from '@ethersproject/units'
import type { ethers } from 'ethers'
import { Fraction, JSBI } from '@/assets/utils/fraction'
import PlayermonNFTAbi from '@/assets/data/abi/PlayermonNFT.json'
import ERC20Abi from '@/assets/data/abi/ERC20.json'
import UNSRegistryAbi from '@/assets/data/abi/UNSRegistry.json'
import { StatsTokenomics, CoinGeckoApiResponse } from '../composables/types'

let ether: typeof ethers,
  provider: ethers.providers.StaticJsonRpcProvider,
  pymContract: ethers.Contract,
  sgemContract: ethers.Contract,
  playermonNftContract: ethers.Contract,
  unsRegistryContract: ethers.Contract

const TOKEN_PRICES = "playermon_classic_0c7315ef6e83550b5db21352573c464be1a11721_TOKEN_PRICES"
const BURN_ADDRESS = '0x000000000000000000000000000000000000dead'

export const usePolygonBlockchainStore = defineStore('usePolygonBlockchainStore', () => {
  const {
    isTestnet,
    rpcUrl,
    pymContractAddress,
    sgemContractAddress,
    playermonNftContractAddress,
    unsRegistryContractAddress,
    apiUrl
  } = useTestnet()
  const tokenomics = ref({
    isLoading: true,
    pymList: [],
    maximumSupply: JSBI.BigInt(0),
    burn: JSBI.BigInt(0),
    circulatingSupply: JSBI.BigInt(0),
    sgem: JSBI.BigInt(0)
  })
  const userBalance = ref({
    isLoading: true,
    pymBalance: '0',
    sgemBalance: '0',
    maticBalance: '0'
  })
  const tokenPrices = ref({
    lastUpdated: 0,
    pymPrice: '1451440000000000',
    maticPrice: '663789000000000000'
  })

  const isClient = async () => {
    if (import.meta.client) {
      if (!ether) {
        const { ethers: importedEthers } = await import('ethers')
        ether = importedEthers
      }
      if (!provider) {
        provider = new ether.providers.StaticJsonRpcProvider(rpcUrl)
      }
      if (!pymContract) {
        pymContract = new ether.Contract(pymContractAddress, ERC20Abi, provider)
      }
      if (!sgemContract) {
        sgemContract = new ether.Contract(
          sgemContractAddress,
          ERC20Abi,
          provider
        )
      }
      if (!playermonNftContract) {
        playermonNftContract = new ether.Contract(
          playermonNftContractAddress,
          PlayermonNFTAbi,
          provider
        )
      }
      if (!unsRegistryContract && !isTestnet.value) {
        unsRegistryContract = new ether.Contract(
          unsRegistryContractAddress,
          UNSRegistryAbi,
          provider
        )
      }
      return true
    } else {
      return false
    }
  }

  const refreshTokenomic = async () => {
    if (!tokenomics.value.isLoading) return

    const response = await $fetch<StatsTokenomics[]>(
      'https://api.playermon.com/api/stats/getallstats'
    )

    const maximumAmount = parseEther('1000000000')
    let burnAmount = BigNumber.from('0')

    let circulatingSupplyAmount = parseEther('1000000000')
    let sgemAmount = BigNumber.from('0')

    if (await isClient()) {
      burnAmount = await pymContract.balanceOf(BURN_ADDRESS)
      sgemAmount = await sgemContract.totalSupply()
    }

    for (const item of response) {
      const pymAmount = parseUnits(item.pymAmount)
      circulatingSupplyAmount = circulatingSupplyAmount.sub(pymAmount)
    }

    tokenomics.value = {
      isLoading: false,
      pymList: response,
      maximumSupply: JSBI.BigInt(maximumAmount),
      burn: JSBI.BigInt(burnAmount),
      circulatingSupply: JSBI.BigInt(circulatingSupplyAmount),
      sgem: JSBI.BigInt(sgemAmount)
    }
  }

  const refreshBalance = async () => {
    if (await isClient()) {
      const authStore = useAuthStore()
      const { authState } = storeToRefs(authStore)
      if (authState.value.isAuthenticated && authState.value.userWallet) {
        const pymAmount = await pymContract.balanceOf(
          authState.value.userWallet
        )
        const sgemAmount = await sgemContract.balanceOf(
          authState.value.userWallet
        )
        const maticAmount = await provider.getBalance(
          authState.value.userWallet
        )
        userBalance.value = {
          isLoading: false,
          pymBalance: pymAmount.toString(),
          sgemBalance: sgemAmount.toString(),
          maticBalance: maticAmount.toString(),
        }
      }
    }
  }

  const refreshPrice = async () => {
    if (await isClient()) {
      if (tokenPrices.value.lastUpdated + 600000 > Date.now()) {
        return // No need to refresh within 10 minutes
      }

      let response = await $fetch<CoinGeckoApiResponse>(
        'https://api.coingecko.com/api/v3/simple/price',
        {
          params: {
            ids: ['playermon,matic-network'],
            vs_currencies: 'usd'
          }
        }
      )

      const pymPrice = parseEther(response.playermon.usd.toString())
      const maticPrice = parseEther(
        response['matic-network'].usd.toString())

      tokenPrices.value = {
        lastUpdated: Date.now(),
        pymPrice: pymPrice.toString(),
        maticPrice: maticPrice.toString()
      }

      if (import.meta.client) {
        window.localStorage.setItem(TOKEN_PRICES, JSON.stringify(tokenPrices.value))
      }
    }
  }

  const refreshPlayermon = async (id: string) => {
    await $fetch(`${apiUrl}/playermon/syncPlayermonByToken/${id}`, {
      method: 'POST'
    })
  }

  const getDomainName = async (walletAddress: string) => {
    if (await isClient()) {
      if (isTestnet) return ''
      const domainName = await unsRegistryContract.reverseNameOf(
        walletAddress
      )
      return domainName
    }
    return ''
  }

  // Run on hydration
  if (import.meta.client) {
    const tokenPricesCache = window.localStorage.getItem(TOKEN_PRICES)
    if (tokenPricesCache) {
      tokenPrices.value = JSON.parse(tokenPricesCache)
    }
    refreshPrice()
  }

  return {
    tokenomics,
    userBalance: skipHydrate(userBalance),
    tokenPrices: skipHydrate(tokenPrices),
    refreshTokenomic,
    refreshBalance,
    refreshPrice,
    refreshPlayermon,
    getDomainName
  }
})
