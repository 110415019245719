import { defineStore, skipHydrate } from 'pinia'

interface AuthState {
  isAuthenticated: boolean
  userWallet?: string
  domainName?: string
  chainId?: number
  sessionToken: string
  refreshToken: string
  username: string
  email?: string
  qrcodeToken?: string
}

const STORAGE_NAME = 'playermon_classic_0c7315ef6e83550b5db21352573c464be1a11721_auth'
const DEFAULT_STATE: AuthState = {
  isAuthenticated: false,
  userWallet: '',
  domainName: '',
  chainId: 0,
  sessionToken: '',
  username: '',
  email: '',
  qrcodeToken: '',
  refreshToken: ''
}

export const useAuthStore = defineStore('useAuthStore', () => {
  const { connectWallet, requestSignature } = useConnectWallet()
  const { addNewErrorToast, addNewSuccessToast } = useToast()
  const { closeModal } = useProfileModal()
  const { apiUrl } = useTestnet()
  const router = useRouter()
  const { getDomainName } = usePolygonBlockchainStore()
  const inventoryStore = useInventoryStore()

  // States
  const authState = ref<AuthState>(DEFAULT_STATE)
  const statusEmailVerification = ref({
    status: -1,
    message: ''
  })

  // Methods
  const updateState = (newState: AuthState) => {
    authState.value = newState
    if (import.meta.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(newState))
    }
    refreshStatusEmailVerification()
  }

  const resetState = () => {
    authState.value = DEFAULT_STATE
    if (import.meta.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(DEFAULT_STATE))
    }
  }

  const signIn = async () => {
    const { userWallet, chainId } = await connectWallet()
    const timestamp = new Date().getTime()
    const message = `Hi ${userWallet}! Welcome to Playermon! Sign this message to prove you have access to this wallet and we'll log you in. This won't cost you any Matic. \n\nTimestamp: ${timestamp}`
    const signature = await requestSignature(message)
    const loginPayload = {
      walletAddress: userWallet,
      timestamp,
      message,
      signature
    }

    const response = await $fetch<LoginResponseType>(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: loginPayload
    })
    if (response.status === 1) {
      const domainName = await getDomainName(userWallet)
      const route = useRoute()
      updateState({
        ...authState.value,
        isAuthenticated: true,
        userWallet,
        domainName,
        chainId,
        sessionToken: response.data.session_token,
        refreshToken: response.data.refresh_token,
        username: response.data.username
      })
      getQRCode()
      if (route.path === '/link-tma') {
        return true
      }
      router.push({ path: '/inventory'})
    }
  }

  const signOut = async () => {
    await $fetch(`${apiUrl}/auth/logout`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authState.value.sessionToken}`
      }
    })
    updateState(DEFAULT_STATE)
    inventoryStore.resetState()
    router.push({ path: '/' })
    closeModal()
  }

  const getQRCode = async () => {
    const response = await $fetch<GenerateQrResponseType>(
      `${apiUrl}/auth/qrToken`,
      {
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        }
      }
    )
    if (response.status === 1) {
      updateState({
        ...authState.value,
        qrcodeToken: response.data?.qr_token
      })
    }
  }

  const regenerateQRCode = async () => {
    const response = await $fetch<GenerateQrResponseType>(
      `${apiUrl}/auth/reGenQrToken`,
      {
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        }
      }
    )
    if (response.status === 1) {
      addNewSuccessToast('Regenerate QR Code successfully')
      updateState({
        ...authState.value,
        qrcodeToken: response.data?.qr_token
      })
    } else if (authState.value.refreshToken) {
      reRefreshToken(authState.value.refreshToken)
    } else if (response.status === 0) {
      await signOut()
      addNewErrorToast('Please Sign In again')
    }
  }

  const updateUsername = async (username: string) => {
    if (username.length > 20) {
      addNewErrorToast('Username cannot be longer than 20 characters')
      return
    }
    const response = await $fetch<UpdateUsernameResponseType>(
      `${apiUrl}/auth/updateUsername`,
      {
        method: 'PATCH',
        body: {
          username
        },
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        }
      }
    )
    if (response.status === 1) {
      addNewSuccessToast('Username updated successfully')
      updateState({
        ...authState.value,
        username
      })
    }
  }

  const updateEmail = async (newEmailAddress: string) => {
    const response = await $fetch<UpdateUsernameResponseType>(
      `${apiUrl}/auth/updateEmail`,
      {
        method: 'POST',
        body: {
          newEmailAddress
        },
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        }
      }
    )
    if (response.status === 1) {
      addNewSuccessToast('The  email have sent')
      router.push('/settings/verify?category=email')
    }

    if (response.status === 0) {
      addNewErrorToast(response.message)
    }
  }

  const reRefreshToken = async (refreshToken: string) => {
    if (!refreshToken) {
      addNewErrorToast('Invalid credentials. Please sign out and sign in again')
      return
    }
    const response = await $fetch<LoginResponseType>(
      `${apiUrl}/auth/refreshToken`,
      {
        method: 'POST',
        body: {
          refreshToken
        }
      }
    )
    if (response.status === 1) {
      updateState({
        ...authState.value,
        refreshToken: response.data.refresh_token,
        sessionToken: response.data.session_token
      })
      await regenerateQRCode()
    } else {
      await signOut()
      addNewErrorToast('Please Sign In again')
    }
  }

  const requestEmailVerification = async (emailAddress: string) => {
    if (!emailAddress) {
      addNewErrorToast('Please fill up the email address')
    }
    const response = await $fetch(
      `${apiUrl}/userMilestones/emailVerificationRequest`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        },
        body: {
          emailAddress
        }
      }
    )
    if (response.status === 1) {
      addNewSuccessToast('The  email have sent')
      router.push('/settings/verify?category=email')
    }

    if (response.status === 0) {
      addNewErrorToast(response.message)
    }
  }

  const requestPlayermon = async (quantity: number) => {
    const response = await $fetch(`${apiUrl}/auth/requestPlayermons`, {
      method: 'POST',
      body: {
        quantity
      },
      headers: {
        Authorization: `Bearer ${authState.value.sessionToken}`
      }
    })
    if (response.status === 1) {
      addNewSuccessToast(
        `${quantity} Playermon have sent to the account ${authState.value.username}`
      )
    }

    if (response.status === 0) {
      addNewErrorToast(response.message)
    }
  }

  const verifyEmailAddress = async (
    verificationCode: string | LocationQueryValue[]
  ) => {
    if (!verificationCode) {
      addNewErrorToast('There is something wrong...')
    }
    const response = await $fetch(
      `${apiUrl}/userMilestones/verifyEmailVerificationCode`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        },
        body: {
          verificationCode
        }
      }
    )

    return response
  }

  const completeBindTmaWithPlayermonClassic = async (input: {
    tgUserID: string
    deadlineTimestamp: number
    signature: {
      r: string
      v: number
      s: string
    }
  }) => {
    try {
      await $fetch<{
        success: boolean
        message: string
        status: number
      }>(`${apiUrl}/auth/completeBindTmaWithTgUserID`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        },
        body: input
      }).then((res) => {
        if (res.status === 0) {
          addNewErrorToast(res.message)
        } else {
          addNewSuccessToast(
            'Complete bind Telegram Mini App with Playermon Classic'
          )
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  const refreshStatusEmailVerification = async () => {
    if (!authState.value.isAuthenticated) {
      statusEmailVerification.value = {
        status: 0,
        message: ''
      }
      return
    }

    const response = await $fetch(
      `${apiUrl}/userMilestones/getStatusEmailVerification`,
      {
        headers: {
          Authorization: `Bearer ${authState.value.sessionToken}`
        }
      }
    )
    statusEmailVerification.value = response
  }

  // Run on hydration
  if (import.meta.client) {
    const authStorage = window.localStorage.getItem(STORAGE_NAME)
    if (authStorage) {
      authState.value = JSON.parse(authStorage)
      refreshStatusEmailVerification()
    }
  }

  return {
    authState: skipHydrate(authState),
    statusEmailVerification: skipHydrate(statusEmailVerification),
    signIn,
    signOut,
    getQRCode,
    regenerateQRCode,
    updateUsername,
    updateEmail,
    reRefreshToken,
    requestEmailVerification,
    requestPlayermon,
    verifyEmailAddress,
    refreshStatusEmailVerification,
    resetState,
    completeBindTmaWithPlayermonClassic
  }
})
