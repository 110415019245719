import { defineStore, skipHydrate } from 'pinia'
import { BigNumber } from '@ethersproject/bignumber'
import type { ethers } from 'ethers'
import PlayermonNFTAbi from '@/assets/data/abi/PlayermonNFT.json'
import PlayermonSBNFTAbi from '@/assets/data/abi/PlayermonSBNFT.json'
import ERC20Abi from '@/assets/data/abi/ERC20.json'
import PlayermonItemNFTAbi from '@/assets/data/abi/PlayermonItemsNFT.json'
import ListPlayermonItemsNFT from '@/assets/data/listPlayermonItemsNFT.json'
import PlayermonViewAbi from '@/assets/data/abi/PlayermonView.json'

let ether: typeof ethers,
  provider: ethers.providers.StaticJsonRpcProvider,
  playermonNftContract: ethers.Contract,
  SBNFTplayermonContract: ethers.Contract,
  pbpsContract: ethers.Contract,
  playermonItemNFTContract: ethers.Contract,
  playermonViewContract: ethers.Contract

interface InventoryState {
  approvalPlayermonNFTonMarketplaceV3: boolean
  approvalPlayermonNFTonSBSwap: boolean
  approvalSBNFTonSBSwap: boolean
  approvalItemNFTonMarketplace: boolean
  approvalItemNFTonBreedingManagerV2: boolean
  playermonsArray: IPlayermon[]
  soulboundedPlayermonsArray: IPlayermon[]
  materialArray: Material[]
  playermonItemArray: PlayermonItem[]
  totalPlayermons: number
  totalBreedingPod: number
}

const STORAGE_NAME = 'playermon_classic_0c7315ef6e83550b5db21352573c464be1a11721_inventory'
const DEFAULT_STATE: InventoryState = {
  approvalPlayermonNFTonMarketplaceV3: false,
  approvalPlayermonNFTonSBSwap: false,
  approvalSBNFTonSBSwap: false,
  approvalItemNFTonMarketplace: false,
  approvalItemNFTonBreedingManagerV2: false,
  playermonsArray: [],
  soulboundedPlayermonsArray: [],
  materialArray: [],
  playermonItemArray: [],
  totalPlayermons: 0,
  totalBreedingPod: 0
}

const playermonsPerPage = 50

const pendingSBPlayermon = ref(true)
const pendingMaterial = ref(true)

export const useInventoryStore = defineStore('useInventoryStore', () => {
  const {
    rpcUrl,
    pbpsContractAddress,
    playermonNftContractAddress,
    playermonNftSoulboundContractAddress,
    playermonItemContractAddress,
    metadataUrl,
    playermonViewAddress
  } = useTestnet()

  const inventoryState = ref<InventoryState>(DEFAULT_STATE)
  const pendingPlayermon = ref<boolean>(true)
  const pendingItemNFT = ref<boolean>(true)

  // Methods
  const updateState = (newState: InventoryState) => {
    inventoryState.value = newState
    if (import.meta.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(newState))
    }
  }

  const resetState = () => {
    inventoryState.value = DEFAULT_STATE
    if (import.meta.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(DEFAULT_STATE))
    }
  }

  // Since Ethers don't and shouldn't work on Rendering server
  const isClient = async () => {
    if (import.meta.client) {
      if (!ether) {
        ether = await import('ethers')
      }
      if (!provider) {
        provider = new ether.providers.StaticJsonRpcProvider(rpcUrl)
      }
      if (!playermonNftContract) {
        playermonNftContract = new ether.Contract(
          playermonNftContractAddress,
          PlayermonNFTAbi,
          provider
        )
      }
      if (!SBNFTplayermonContract) {
        SBNFTplayermonContract = new ether.Contract(
          playermonNftSoulboundContractAddress,
          PlayermonSBNFTAbi,
          provider
        )
      }

      if (!pbpsContract) {
        pbpsContract = new ether.Contract(
          pbpsContractAddress,
          ERC20Abi,
          provider
        )
      }
      if (!playermonItemNFTContract) {
        playermonItemNFTContract = new ether.Contract(
          playermonItemContractAddress,
          PlayermonItemNFTAbi,
          provider
        )
      }

      if (!playermonViewContract) {
        playermonViewContract = new ether.Contract(
          playermonViewAddress,
          PlayermonViewAbi,
          provider
        )
      }

      return true
    } else {
      return false
    }
  }

  const findClass = (playermonMetada: unknown) => {
    const value = playermonMetada.attributes.find(
      (attribute: { trait_type: string }) => attribute.trait_type === 'class'
    )
    return value.value
  }

  const getApprovalStatus = async () => {
    if (await isClient()) {
      const authStore = useAuthStore()
      const { authState } = storeToRefs(authStore)
      if (authState.value.isAuthenticated) {
        console.log('authState.value.userWallet', authState.value.userWallet)
        const allAprovalFromSmartContract =
          await playermonViewContract.getApprovalNFT(
            authState.value.userWallet
          )
        inventoryState.value.approvalPlayermonNFTonMarketplaceV3 =
          allAprovalFromSmartContract[0]

        inventoryState.value.approvalPlayermonNFTonSBSwap =
          allAprovalFromSmartContract[1]

        inventoryState.value.approvalSBNFTonSBSwap =
          allAprovalFromSmartContract[2]

        inventoryState.value.approvalItemNFTonMarketplace =
          allAprovalFromSmartContract[3]

        inventoryState.value.approvalItemNFTonBreedingManagerV2 =
          allAprovalFromSmartContract[4]
        updateState(inventoryState.value)
      }
    }
    return false
  }

  // Always take the first page
  const refreshPlayermon = async () => {
    try {
      if (await isClient()) {
        const authStore = useAuthStore()
        const { authState } = storeToRefs(authStore)
        console.log('refreshPlayermon', authState.value.userWallet)
        if (authState.value.isAuthenticated) {
          let newPlayermonsArray: IPlayermon[] = []
          const totalPlayermons = await playermonNftContract.balanceOf(
            authState.value.userWallet
          )

          const i = 0
          const max = Math.min(i + playermonsPerPage, totalPlayermons)
          const playermons = await playermonViewContract.getPlayermonNFTByOwner(
            authState.value.userWallet,
            0,
            max
          )

          const bManagerPmons = []
          const tokenIds: number[] = []
          const breedingCounts: number[] = []
          bManagerPmons.push(...playermons)

          if (bManagerPmons[0] && Array.isArray(bManagerPmons[0])) {
            const playermonIds = bManagerPmons[0].map((id: BigNumber) =>
              id.toNumber()
            )
            if (playermonIds.length > 0) {
              tokenIds.push(...playermonIds)
            }
          }

          if (bManagerPmons[3] && Array.isArray(bManagerPmons[3])) {
            const counts = bManagerPmons[3].map((id: BigNumber) =>
              id.toNumber()
            )
            if (counts.length > 0) {
              breedingCounts.push(...counts)
            }
          }

          if (tokenIds.length > 0) {
            const prices = bManagerPmons[2]
            const isHatches = bManagerPmons[1]

            const hatchTimestamp: string[] = []

            for (let i = 0; i < tokenIds.length; i++) {
              hatchTimestamp.push(isHatches[i].toString())
            }

            const metadataPromises = []
            for (let i = 0; i < tokenIds.length; i++) {
              const url = `${metadataUrl}playermon/${tokenIds[i]}?hatachableTime=${hatchTimestamp[i]}`
              metadataPromises.push($fetch(url))
            }

            const metadataArray = await Promise.all(metadataPromises)

            for (let i = 0; i < tokenIds.length; i++) {
              let metadata = metadataArray[i]
              if (
                BigNumber.from(isHatches[i]).eq(BigNumber.from('0')) &&
                findClass(metadata) === 'Egg'
              ) {
                metadata = await $fetch(
                  `${metadataUrl}playermon/${tokenIds[i]}?hatachableTime=${
                    hatchTimestamp[i]
                  }&currentTime=${Date.now()}`
                )
              }
              newPlayermonsArray.push({
                playermon_id: Number(tokenIds[i]),
                isHatched: BigNumber.from(isHatches[i]).eq(BigNumber.from('0')),
                breedingCount: BigNumber.from(breedingCounts[i]).toString(),
                image: metadata.image,
                classFamily: findClass(metadata),
                attributes: metadata.attributes,
                price: prices[i].toString(),
                hatchableTimestamp: hatchTimestamp[i]
              })
            }
            inventoryState.value.playermonsArray = newPlayermonsArray
            updateState(inventoryState.value)
            pendingPlayermon.value = false
          }
        }
      }
    } catch (e) {
      console.error('Error fetching playermons:', e)
    }
  }

  const refreshSoulboundedPlayermon = async () => {
    try {
      if (await isClient()) {
        const authStore = useAuthStore()
        const { authState } = storeToRefs(authStore)
        console.log('refreshSoulboundedPlayermon', authState.value)
        console.log('refreshSoulboundedPlayermon', authState.value.userWallet)
        if (authState.value.isAuthenticated) {
          const newSoulboundedPlayermonsArray: IPlayermon[] = []
          const totalSBNFT = await SBNFTplayermonContract.balanceOf(
            authState.value.userWallet
          )

          const i = 0
          const max = Math.min(i + playermonsPerPage, totalSBNFT)
          const playermonsSB =
            await playermonViewContract.getPlayermonSBNFTByOwner(
              authState.value.userWallet,
              0,
              max
            )

          const bManagerPmons = []
          const tokenIds: number[] = []
          const breedingCounts: number[] = []
          bManagerPmons.push(...playermonsSB)

          if (bManagerPmons[0] && Array.isArray(bManagerPmons[0])) {
            const playermonIds = bManagerPmons[0].map((id: BigNumber) =>
              id.toNumber()
            )
            if (playermonIds.length > 0) {
              tokenIds.push(...playermonIds)
            }
          }

          if (bManagerPmons[1] && Array.isArray(bManagerPmons[1])) {
            const counts = bManagerPmons[1].map((id: BigNumber) =>
              id.toNumber()
            )
            if (counts.length > 0) {
              breedingCounts.push(...counts)
            }
          }

          if (tokenIds.length > 0) {
            const metadataPromises = []
            for (let i = 0; i < tokenIds.length; i++) {
              const url = `${metadataUrl}playermon/${
                tokenIds[i]
              }?&currentTime=${Date.now()}`
              metadataPromises.push($fetch(url))
            }

            const metadataArray = await Promise.all(metadataPromises)

            tokenIds.forEach((id, index) => {
              const metadata = metadataArray[index]
              newSoulboundedPlayermonsArray.push({
                playermon_id: Number(id),
                breedingCount: BigNumber.from(breedingCounts[index]).toString(),
                image: metadata.image,
                classFamily: findClass(metadata),
                attributes: metadata.attributes
              })
            })
            inventoryState.value.soulboundedPlayermonsArray =
              newSoulboundedPlayermonsArray
            updateState(inventoryState.value)
            pendingSBPlayermon.value = false
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const refreshMaterial = async () => {
    const materialInventoryArray = [
      {
        image: 'https://download.playermon.com/img/staking/pbps.png',
        name: 'Playermon Breeding Pod Shards (PBPS)',
        quantity: 0
      }
    ]
    try {
      if (await isClient()) {
        const authStore = useAuthStore()
        const { authState } = storeToRefs(authStore)
        if (authState.value.isAuthenticated) {
          const pbpsAmount = await pbpsContract.balanceOf(
            authState.value.userWallet
          )
          materialInventoryArray[0].quantity = pbpsAmount
          inventoryState.value.materialArray = materialInventoryArray
          updateState(inventoryState.value)
          pendingMaterial.value = false
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const refreshItemsNFT = async () => {
    const itemsNFTDetail = ListPlayermonItemsNFT
    const itemsNFTList = []
    try {
      if (await isClient()) {
        const authStore = useAuthStore()
        const { authState } = storeToRefs(authStore)
        if (authState.value.isAuthenticated) {
          const ids = []
          const addresses = []
          for (const i in itemsNFTDetail) {
            addresses.push(authState.value.userWallet)
            ids.push(itemsNFTDetail[i].ItemId)
          }
          const items = await playermonItemNFTContract.balanceOfBatch(
            addresses,
            ids
          )
          for (const i in itemsNFTDetail) {
            if (Number(items[i]) !== 0) {
              itemsNFTList.push({
                name: itemsNFTDetail[i].name,
                itemId: itemsNFTDetail[i].ItemId,
                totalItems: Number(items[i]),
                image: itemsNFTDetail[i].image
              })
            }
            if (itemsNFTDetail[i].ItemId === 65 && Number(items[i]) !== 0) {
              inventoryState.value.totalBreedingPod = Number(items[i])
            }
          }
          inventoryState.value.playermonItemArray = itemsNFTList
          updateState(inventoryState.value)
          pendingItemNFT.value = false
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Run on hydration
  if (import.meta.client) {
    const inventoryCache = window.localStorage.getItem(STORAGE_NAME)
    if (inventoryCache) {
      inventoryState.value = JSON.parse(inventoryCache)
    }
  }

  return {
    inventoryState: skipHydrate(inventoryState),
    pendingPlayermon: skipHydrate(pendingPlayermon),
    pendingMaterial,
    pendingItemNFT,
    getApprovalStatus,
    refreshPlayermon,
    refreshSoulboundedPlayermon,
    refreshMaterial,
    refreshItemsNFT,
    resetState
  }
})
