import { parseFixed } from '@ethersproject/bignumber'
import { BigNumber } from 'ethers'
import { getParsedEthersError } from '@enzoferey/ethers-error-parser'
import type { ethers } from 'ethers'
import type { EthersError } from '@enzoferey/ethers-error-parser'
import useToast from './useToast'
import useTestnet from './useTestnet'
import useWaitingModal from './useWaitingModal'
import { ConnectWalletTypes, isMyError } from './types'
import PymClaimAbi from '@/assets/data/abi/PymTokenClaim.json'
import SgemTokenClaimAbi from '@/assets/data/abi/SgemTokenClaim.json'
import BreedingPodShardMinerAbi from '@/assets/data/abi/BreedingPodShardMiner.json'
import ERC20Abi from '@/assets/data/abi/ERC20.json'
import PlayermonBreedingPodCraftingAbi from '@/assets/data/abi/PlayermonBreedingPodCrafting.json'
import PlayermonMarketplaceV3Abi from '@/assets/data/abi/PlayermonNftMarketplaceV3.json'
import PlayermonBreedingManagerAbi from '@/assets/data/abi/PlayermonBreedingManager.json'
import PlayermonBreedingV2ManagerAbi from '@/assets/data/abi/PlayermonBreedingV2Manager.json'
import PlayermonNFTAbi from '@/assets/data/abi/PlayermonNFT.json'
import ChristmasGiftBoxSaleAbi from '@/assets/data/abi/ChristmasGiftBoxSale.json'
import PlayermonCraftingV2Abi from '@/assets/data/abi/PlayermonCraftingV2.json'
import SgemDepositAbi from '@/assets/data/abi/SgemDeposit.json'
import SoulBoundSwapAbi from '@/assets/data/abi/SoulBoundSwap.json'
import ItemMarketplaceAbi from '@/assets/data/abi/ItemMarketplace.json'
import PlayermonLunarSpaceBoxCraftingAbi from '@/assets/data/abi/PlayermonLunarSpaceBoxCrafting.json'
import PYMTokenFaucetAbi from '@/assets/data/abi/PYMTokenFaucet.json'
import PlayermonNFTFaucetAbi from '@/assets/data/abi/PlayermonNFTFaucet.json'
import BreedingPodFaucetAbi from '@/assets/data/abi/BreedingPodFaucet.json'
import LinkTmaPlayermonAbi from '@/assets/data/abi/LinkTmaPlayermon.json'

const useConnectWallet = () => {
  let ether: typeof ethers,
    provider: ethers.providers.ExternalProvider,
    signer: ethers.providers.JsonRpcSigner,
    staticProvider: ethers.providers.StaticJsonRpcProvider
  const { addNewErrorToast, addNewSuccessToast } = useToast()
  const {
    isTestnet,
    rpcUrl,
    blockexplorer,
    chainId,
    sgemClaimContractAddress,
    playermonBreedingPodCraftingAddress,
    playermonMarketplaceV3ContractAddress,
    breedingManagerContractAddress,
    breedingV2ManagerContractAddress,
    pymContractAddress,
    playermonNftContractAddress,
    christmasGiftBoxContractAddress,
    playermonCraftingV2Address,
    sgemDepositAddress,
    itemMarketplaceAddress,
    playermonLunarSpaceBoxCraftingAddress,
    pymTokenFaucetAddress,
    playermonNFTFaucetAddress,
    sgemContractAddress,
    soulBoundSwapAddress,
    breedingPodFaucetAddress,
    linkTmaPlayermonAddress
  } = useTestnet()
  const { openWaiting, closeWaiting } = useWaitingModal()

  const isClient = async () => {
    if (import.meta.client) {
      if (!ether) {
        ether = await import('ethers')
      }

      if (!provider) {
        provider = window.ethereum
        if (!provider) {
          addNewErrorToast('Please install MetaMask extension')
          return false
        }
      }

      if (!staticProvider) {
        staticProvider = new ether.providers.StaticJsonRpcProvider(rpcUrl)
      }

      return true
    } else {
      return false
    }
  }

  const changeNetwork = async () => {
    if (await isClient()) {
      try {
        await provider.request?.({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: `Polygon ${isTestnet ? 'Testnet' : 'Mainnet'}`,
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'matic',
                decimals: 18
              },
              rpcUrls: [rpcUrl],
              blockExplorerUrls: [blockexplorer]
            }
          ]
        })
        return true
      } catch (error) {
        console.error('Failed to setup the network in Metamask:', error)
        return false
      }
    }
  }

  const connectWallet = async (): Promise<ConnectWalletTypes> => {
    if (await isClient()) {
      try {
        const ethersProvider = new ether.providers.Web3Provider(window.ethereum)

        await provider.request?.({
          method: 'eth_requestAccounts'
        })

        signer = await ethersProvider.getSigner()
        const userNetwork = await signer.getChainId()

        if (userNetwork !== chainId) {
          await changeNetwork()
        }

        const walletAddress = await signer.getAddress()
        return {
          isConnected: true,
          userWallet: walletAddress,
          chainId: userNetwork
        }
      } catch (err) {
        return {
          isConnected: false
        }
      }
    }
    return {
      isConnected: false
    }
  }

  const requestSignature = async (message: string) => {
    if (!signer) {
      await connectWallet()
    }
    const signature = await signer.signMessage(message)
    return signature
  }

  const broadcastSignatureBindTelegramMiniApp = async (
    telegramUserID: string
  ): Promise<ethers.Signature | undefined> => {
    if (!signer) {
      await connectWallet()
    }
    try {
      const message = ether.utils.solidityKeccak256(
        ['uint256'],
        [Number(telegramUserID)]
      )

      const messageBytes = ether.utils.arrayify(message)
      const signature = await signer.signMessage(messageBytes)
      // Split the signature
      const sig = ether.utils.splitSignature(signature)
      return sig
    } catch (err) {}
  }

  const getExpiredTimeSignatureTelegramMiniApp = async (): Promise<number> => {
    const deadlineTimestampInMinutes = 60 * 30
    const block = (await staticProvider.getBlock('latest')).timestamp
    return block + deadlineTimestampInMinutes
  }

  const broadcastClaimToken = async (
    loginAddress: string,
    contractAddress: string,
    isTGE: boolean
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    const contract = new ether.Contract(contractAddress, PymClaimAbi, signer)
    try {
      if (isTGE) {
        const tx = await contract.claimTGE()
        await tx.wait()
        addNewSuccessToast(
          'View on Polygonscan',
          'Claim Broadcasted',
          `https://polygonscan.com/tx/${tx.hash}`
        )
      } else {
        const tx = await contract.claim()
        await tx.wait()
        addNewSuccessToast(
          'View on Polygonscan',
          'Claim Broadcasted',
          `https://polygonscan.com/tx/${tx.hash}`
        )
      }
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const ethersWatchToken = async (tokenAddress: string) => {
    let tokenSymbol = ''
    let tokenDecimals = 18
    let tokenImage = ''

    switch (tokenAddress) {
      case pymContractAddress:
        tokenSymbol = 'PYM'
        tokenImage = 'https://download.playermon.com/pymToken.png'
        break
      case sgemContractAddress:
        tokenSymbol = 'SGEM'
        tokenImage = 'https://download.playermon.com/sgemToken.png'
        break
      default:
        return
    }

    if (await isClient()) {
      try {
        const tokenAdded = await provider.request?.({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage // A string url of the token logo
            }
          }
        })
        if (tokenAdded) {
          addNewSuccessToast('Token added to Metamask')
        } else {
          addNewErrorToast('Failed to add token to Metamask')
        }
      } catch (err) {
        addNewErrorToast('Failed to add token to Metamask')
      }
    }
  }

  const broadcastClaimSgemToken = async (
    loginAddress: string,
    nonce: string,
    sgemAmount: string,
    splitSigV: string,
    splitSigR: string,
    splitSigS: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const maticAmount = await signer.getBalance()
      const readableMaticAmount = ether.utils.formatEther(maticAmount)
      if (Number(readableMaticAmount) < 0.01) {
        addNewErrorToast(
          'You need to have minimum 0.01 MATIC in this account',
          'Error'
        )
        return false
      }
      const contract = new ether.Contract(
        sgemClaimContractAddress,
        SgemTokenClaimAbi,
        signer
      )
      const tx = await contract.claimSGEM(
        loginAddress,
        nonce,
        sgemAmount,
        splitSigV,
        splitSigR,
        splitSigS
      )
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastDepositSgemToken = async (
    loginAddress: string,
    amount: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        sgemDepositAddress,
        SgemDepositAbi,
        signer
      )
      const sgemAmount = ether.utils.parseEther(amount.toString())
      const tx = await contract.deposit(sgemAmount)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastApproveErc20Token = async (
    loginAddress: string,
    tokenAddress: string,
    smartContractAddress: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(tokenAddress, ERC20Abi, signer)
      const tx = await contract.approve(
        smartContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastEarnedStaking = async (
    loginAddress: string,
    poolId: string,
    smartContractAddress: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        smartContractAddress,
        BreedingPodShardMinerAbi,
        signer
      )
      const tx = await contract.harvest(loginAddress, poolId)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastStakeToken = async (
    loginAddress: string,
    poolId: string,
    smartContractAddress: string,
    value: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const parsedValue = parseFixed(value, 18)
      const contract = new ether.Contract(
        smartContractAddress,
        BreedingPodShardMinerAbi,
        signer
      )
      const tx = await contract.deposit(poolId, parsedValue)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastUnstakeToken = async (
    loginAddress: string,
    poolId: string,
    smartContractAddress: string,
    value: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const parsedValue = parseFixed(value, 18)
      const contract = new ether.Contract(
        smartContractAddress,
        BreedingPodShardMinerAbi,
        signer
      )
      const tx = await contract.withdraw(poolId, parsedValue)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Claim Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastCraftBreedingPod = async (
    loginAddress: string,
    amount: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonBreedingPodCraftingAddress,
        PlayermonBreedingPodCraftingAbi,
        signer
      )
      const tx = await contract.craft(Number(amount))
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastEnablePYMWallet = async (loginAddress: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(pymContractAddress, ERC20Abi, signer)
      const tx = await contract.approve(
        playermonMarketplaceV3ContractAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Enable PYM Wallet for Marketplace',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastEnablePYMSoulboundWallet = async (loginAddress: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(pymContractAddress, ERC20Abi, signer)
      const tx = await contract.approve(
        soulBoundSwapAddress,
        '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
      )
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Enable PYM Wallet for Soulbound',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastEnableNFTWallet = async (
    loginAddress: string,
    tokenAddress: string,
    smartContractAddress: string,
    abi: ethers.ContractInterface
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(tokenAddress, abi, signer)
      const tx = await contract.setApprovalForAll(smartContractAddress, true)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Enable NFT Wallet for Marketplace',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        const parsedEthersError = getParsedEthersError(err as EthersError)
        addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
        console.log(`Revert Error: ${parsedEthersError.context}`)
      }
    }
    return false
  }

  const broadcastEnableSBNFTWallet = async (
    loginAddress: string,
    tokenAddress: string,
    smartContractAddress: string,
    abi: ethers.ContractInterface
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(tokenAddress, abi, signer)
      const tx = await contract.setApprovalForAll(smartContractAddress, true)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Enable NFT Wallet for Soulbound',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err: unknown) {
      if (isMyError(err)) {
        const parsedEthersError = getParsedEthersError(err as EthersError)
        addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
        console.log(`Revert Error: ${parsedEthersError.context}`)
      }
    }
    return false
  }

  const broadcastBuyPlayermon = async (
    loginAddress: string,
    id: string
  ): Promise<boolean> => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.purchase(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Buy Playermon Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBatchBuyPlayermon = async (
    loginAddress: string,
    ids: string[],
    prices: string[]
  ): Promise<boolean> => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.batchPurchase(ids, prices)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Buy Playermon Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastDelistMarketplace = async (
    loginAddress: string,
    id: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.cancelSelling(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Delist Playermon On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBatchDelistMarketplace = async (
    loginAddress: string,
    ids: string[]
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.batchCancelSelling(ids)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Delist Playermon On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastListMarketplace = async (
    loginAddress: string,
    id: string,
    amount: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const price = ether.utils.parseEther(amount.toString())
      const tx = await contract.setSalePrice(id, price)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'List Playermon On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBatchListMarketplace = async (
    loginAddress: string,
    ids: string[],
    prices: string[]
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.setBatchSalePrice(ids, prices)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'List Playermon On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastGiftPlayermon = async (
    loginAddress: string,
    receiver: string,
    id: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonNftContractAddress,
        PlayermonNFTAbi,
        signer
      )
      const tx = await contract.transferFrom(loginAddress, receiver, id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Gift the playermon have broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBatchGiftPlayermon = async (
    loginAddress: string,
    ids: string[],
    receiver: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonMarketplaceV3ContractAddress,
        PlayermonMarketplaceV3Abi,
        signer
      )
      const tx = await contract.batchTransfer(ids, receiver)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Gift the playermon have broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastHatchPlayermon = async (loginAddress: string, id: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        breedingManagerContractAddress,
        PlayermonBreedingManagerAbi,
        signer
      )
      const tx = await contract.hatchPlayermon(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Hatching Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastBreedPlayermon = async (
    loginAddress: string,
    parentA: string,
    parentB: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        breedingV2ManagerContractAddress,
        PlayermonBreedingV2ManagerAbi,
        signer
      )
      const tx = await contract.breed(parentA, parentB)
      const receipt = await tx.wait()
      const childrenId = BigInt(receipt.logs[1].topics[3]).toString()
      addNewSuccessToast(
        'View on Polygonscan',
        'Breeding Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return childrenId
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBoundPlayermon = async (loginAddress: string, id: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        soulBoundSwapAddress,
        SoulBoundSwapAbi,
        signer
      )
      const tx = await contract.bound(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Soulbound Broadcast is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
      console.log(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastUnboundPlayermon = async (
    loginAddress: string,
    id: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        soulBoundSwapAddress,
        SoulBoundSwapAbi,
        signer
      )
      const tx = await contract.unbound(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Unbound Broadcast is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
      console.log(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBuyChristmasGiftBox = async (
    loginAddress: string,
    amount: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    openWaiting()
    try {
      const contract = new ether.Contract(
        christmasGiftBoxContractAddress,
        ChristmasGiftBoxSaleAbi,
        signer
      )
      const tx = await contract.purchase(amount)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Buy Christmas Gift Box Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err?.message)
      }
    }
    closeWaiting()
    return false
  }

  const broadcastUnwrapSpaceBox = async (
    loginAddress: string,
    amount: number,
    smartContractAddress: string,
    abi: ethers.ContractInterface
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    openWaiting()
    try {
      const contract = new ether.Contract(smartContractAddress, abi, signer)
      const tx = await contract.unwrap(amount)
      const result = await tx.wait()
      const itemId = BigNumber.from(
        result.logs[1].data.substring(0, 66)
      ).toString()
      const pymAmount = BigNumber.from(result.logs[2].data)
        .div('1000000000000000000')
        .toString()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Unwrap Space Box Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return { itemId, pymAmount }
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastTransferPlayermonNftItem = async (
    loginAddress: string,
    amount: number,
    smartContractAddress: string,
    abi: ethers.ContractInterface,
    receiver: string,
    tokenId: number,
    type: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(smartContractAddress, abi, signer)
      const tx = await contract.safeTransferFrom(
        loginAddress,
        receiver,
        tokenId,
        amount,
        '0x'
      )
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        `Transfer ${type} Broadcasted is Done`,
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastCraftAPlayermon = async (
    loginAddress: string,
    pymQuantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonCraftingV2Address,
        PlayermonCraftingV2Abi,
        signer
      )
      const tx = await contract.craftA(pymQuantity)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft PYM Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastCraftBPlayermon = async (
    loginAddress: string,
    pymQuantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonCraftingV2Address,
        PlayermonCraftingV2Abi,
        signer
      )
      const tx = await contract.craftB(pymQuantity)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft PYM Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastCraftCPlayermon = async (
    loginAddress: string,
    playermonNftQuantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }

      if (playermonNftQuantity > 50) {
        addNewErrorToast('The max number of Egg can be craft is 50')
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonCraftingV2Address,
        PlayermonCraftingV2Abi,
        signer
      )
      const tx = await contract.craftC(playermonNftQuantity)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft Egg Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err.reason)
      }
    }
    return false
  }

  const broadcastCraftDPlayermon = async (loginAddress: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonCraftingV2Address,
        PlayermonCraftingV2Abi,
        signer
      )
      const tx = await contract.craftD()
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft Breeding Pod ERC1155 Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err.reason)
      }
    }
    return false
  }

  const broadcastCraftEPlayermon = async (
    loginAddress: string,
    breedingPodQuantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }

      if (breedingPodQuantity > 50) {
        addNewErrorToast('The max number of Breeding Pod can be craft is 50')
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonCraftingV2Address,
        PlayermonCraftingV2Abi,
        signer
      )
      const tx = await contract.craftE(breedingPodQuantity)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'Craft Breeding Pod ERC1155 is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        closeWaiting()
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastListItemMarketplace = async (
    loginAddress: string,
    tokenId: number,
    pricePerUnit: string,
    deadline: number,
    quantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        itemMarketplaceAddress,
        ItemMarketplaceAbi,
        signer
      )

      const pymAmount = ether.utils.parseEther(pricePerUnit)

      const tx = await contract.list(tokenId, pymAmount, quantity, deadline)
      await tx.wait()
      closeWaiting()
      addNewSuccessToast(
        'View on Polygonscan',
        'List Item On Marketplace is broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastBuyItemMarketplace = async (
    loginAddress: string,
    id: string,
    txId: number,
    buyingPricePerUnit: number,
    buyingQuantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        itemMarketplaceAddress,
        ItemMarketplaceAbi,
        signer
      )
      const pymAmount = ether.utils.parseEther(String(buyingPricePerUnit))
      const tx = await contract.purchase(txId, id, pymAmount, buyingQuantity)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Buy Item On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastCancelItemListingMarketplace = async (
    loginAddress: string,
    txId: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        itemMarketplaceAddress,
        ItemMarketplaceAbi,
        signer
      )
      const tx = await contract.cancelListing(txId)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Delist Item On Marketplace Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }
  const broadcastCraftingLunarSpaceBox = async (
    loginAddress: string,
    quantity: number
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        playermonLunarSpaceBoxCraftingAddress,
        PlayermonLunarSpaceBoxCraftingAbi,
        signer
      )
      const tx = await contract.craftLunarSpaceBox(quantity)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Crafting Lunar Space Box Broadcasted',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastUnwrapLunarSpaceBox = async (
    loginAddress: string,
    amount: number,
    smartContractAddress: string,
    abi: ethers.ContractInterface
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }

    try {
      const contract = new ether.Contract(smartContractAddress, abi, signer)
      const tx = await contract.unwrap(Number(amount))
      const result: { logs: { topics: Array<string>; data: string }[] } =
        await tx.wait()
      const transformData = result.logs
        .filter(
          item =>
            !item.topics.includes(
              '0x000000000000000000000000000000000000000000000000000000000000dead'
            ) && item.data.length <= 131
        )
        .map(item => {
          return {
            itemId: BigNumber.from(item.data.substring(0, 66)).toString(),
            amount: BigNumber.from(item.data.substring(67, 130)).toString()
          }
        })
      return transformData
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastRequestPYMfromFaucet = async (loginAddress: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }

    if (!isTestnet) {
      return false
    }

    try {
      const contract = new ether.Contract(
        pymTokenFaucetAddress,
        PYMTokenFaucetAbi,
        signer
      )

      const tx = await contract.requestPYM(loginAddress)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Requested PYM from Faucet',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastRequestEggfromFaucet = async (loginAddress: string) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }

    if (!isTestnet) {
      return false
    }

    try {
      const contract = new ether.Contract(
        playermonNFTFaucetAddress,
        PlayermonNFTFaucetAbi,
        signer
      )

      const tx = await contract.requestNFT()

      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Requested Egg from Faucet',
        `https://polygonscan.com/tx/${tx.hash}`
      )

      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastRequestBreedingPodfromFaucet = async (
    loginAddress: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }

    if (!isTestnet) {
      return false
    }

    try {
      const contract = new ether.Contract(
        breedingPodFaucetAddress,
        BreedingPodFaucetAbi,
        signer
      )

      const tx = await contract.requestBreedingPod(loginAddress)

      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Requested Breeding Pod from Faucet',
        `https://polygonscan.com/tx/${tx.hash}`
      )

      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`Revert Error: ${parsedEthersError.context}`)
    }
    return false
  }

  const broadcastHatchV2Playermon = async (
    loginAddress: string,
    id: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        breedingV2ManagerContractAddress,
        PlayermonBreedingV2ManagerAbi,
        signer
      )
      const tx = await contract.hatchPlayermon(id)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Hatching Egg Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastBatchHatchV2Playermon = async (
    loginAddress: string,
    ids: string[]
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        breedingV2ManagerContractAddress,
        PlayermonBreedingV2ManagerAbi,
        signer
      )
      const tx = await contract.hatchPlayermonBatch(ids)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Hatching Egg Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      if (isMyError(err)) {
        addNewErrorToast(err?.message)
      }
    }
    return false
  }

  const broadcastLinkTmaPlayermon = async (
    loginAddress: string,
    tgUserID: string,
    deadlineTimestamp: number,
    v: number,
    r: string,
    s: string
  ) => {
    if (!signer) {
      const { userWallet } = await connectWallet()
      if (userWallet !== loginAddress) {
        addNewErrorToast(
          `Current Address ${userWallet} not match with logged in address ${loginAddress}`
        )
        return false
      }
    }
    try {
      const contract = new ether.Contract(
        linkTmaPlayermonAddress,
        LinkTmaPlayermonAbi,
        signer
      )
      const tx = await contract.bindWallet(tgUserID, deadlineTimestamp, v, r, s)
      await tx.wait()
      addNewSuccessToast(
        'View on Polygonscan',
        'Link TMA Playermon Broadcasted is Done',
        `https://polygonscan.com/tx/${tx.hash}`
      )
      return true
    } catch (err) {
      const parsedEthersError = getParsedEthersError(err as EthersError)
      addNewErrorToast(`${parsedEthersError.context}`)
    }
    return false
  }

  return {
    connectWallet,
    requestSignature,
    ethersWatchToken,
    broadcastClaimToken,
    broadcastClaimSgemToken,
    broadcastDepositSgemToken,
    broadcastApproveErc20Token,
    broadcastEarnedStaking,
    broadcastStakeToken,
    broadcastUnstakeToken,
    broadcastCraftBreedingPod,
    broadcastEnablePYMWallet,
    broadcastEnablePYMSoulboundWallet,
    broadcastEnableNFTWallet,
    broadcastEnableSBNFTWallet,
    broadcastBuyPlayermon,
    broadcastBatchBuyPlayermon,
    broadcastDelistMarketplace,
    broadcastBatchDelistMarketplace,
    broadcastListMarketplace,
    broadcastBatchListMarketplace,
    broadcastGiftPlayermon,
    broadcastBatchGiftPlayermon,
    broadcastHatchPlayermon,
    broadcastBreedPlayermon,
    broadcastBuyChristmasGiftBox,
    broadcastUnwrapSpaceBox,
    broadcastTransferPlayermonNftItem,
    broadcastCraftAPlayermon,
    broadcastCraftBPlayermon,
    broadcastCraftCPlayermon,
    broadcastCraftDPlayermon,
    broadcastCraftEPlayermon,
    broadcastListItemMarketplace,
    broadcastBuyItemMarketplace,
    broadcastCancelItemListingMarketplace,
    broadcastCraftingLunarSpaceBox,
    broadcastUnwrapLunarSpaceBox,
    broadcastRequestPYMfromFaucet,
    broadcastRequestEggfromFaucet,
    broadcastBoundPlayermon,
    broadcastUnboundPlayermon,
    broadcastRequestBreedingPodfromFaucet,
    broadcastHatchV2Playermon,
    broadcastBatchHatchV2Playermon,
    broadcastLinkTmaPlayermon,
    broadcastSignatureBindTelegramMiniApp,
    getExpiredTimeSignatureTelegramMiniApp
  }
}

export default useConnectWallet
