const useProfileModal = () => {
  interface ProfileModalState {
    isOpen: boolean;
    page: 'wallet' | 'qrcode';
  }

  const profileModalState = useState<ProfileModalState>('ProfileModal', () => {
    return {
      isOpen: false,
      page: 'wallet'
    }
  })

  const closeModal = () => {
    profileModalState.value.isOpen = false
  }

  const showModal = (navigateToPage: 'wallet' | 'qrcode') => {
    if (navigateToPage === 'wallet') {
      profileModalState.value.page = 'wallet'
    } else if (navigateToPage === 'qrcode') {
      profileModalState.value.page = 'qrcode'
    }
    profileModalState.value.isOpen = true
  }

  return {
    profileModalState,
    closeModal,
    showModal
  }
}

export default useProfileModal
